import React, { useCallback, useEffect, useState } from 'react';
import ImpersonatePageModel from './Models/ImpersonatePageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { media } from '@glitz/core';
import { applyEditModeAttr } from '../../Features/Shared/Common/Helpers';
import { styled, theme } from '../Theme';
import {
  StopImpersonation,
  StartImpersonation,
} from '../ImpersonatePage/Impersonate';
import TepeList from '../TepeList/TepeList';
import ImpersonatingInfo from '../ImpersonatePage/ImpersonatingInfo';
import ImpersonateListItem from '../TepeList/ImpersonateListItem';
import SearchComponent from '../Shared/SearchComponent/SearchComponent';
import { GenericDropDown } from '../Shared/ValueDropDown/GenericDropDown';
import { FetchPagination } from '../Shared/Pagination/PaginationFetcher';
import Pagination from './../Shared/Pagination/Pagination';
import ImpersonateUserViewModel from '../ImpersonatePage/Models/ImpersonateUserViewModel.interface';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import useMedia from '../Shared/Hooks/useMedia';
import XhtmlComponent from '../Shared/XhtmlComponent/XhtmlComponent';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';

type PaginationDataType = {
  paginationItems: ImpersonateUserViewModel[];
  totalRecords: number;
};

function ImpersonatePage() {
  const {
    translations: {
      'impersonatePage/findUsersToImpersonate': findUsersToImpersonate,
      'impersonatePage/customerName': customerName,
      'impersonatePage/customerId': customerId,
      'impersonatePage/city': cityLabel,
      'impersonatePage/searchForUsers': searchForUsers,
      'impersonatePage/search': search,
      'impersonatePage/filterLabel': filterLabel,
      'impersonatePage/searchLabel': searchLabel,
      'impersonatePage/addressLabel': addressLabel,
      'impersonatePage/zipCodeLabel': zipCodeLabel,
    },
    languageRoute,
  } = useAppSettingsData();
  const {
    heading,
    inEditMode,
    introText,
    mainBody,
    paginationType,
    impersonatePageUrl,
  } = useCurrentPage<ImpersonatePageModel>();
  const [updatedUsers, setUsers] = useState<ImpersonateUserViewModel[]>([]);
  const [currentQuery, setQuery] = useState<string>('');
  const [currentFilter, setFilter] = useState<string>('ASC');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const UserState = useUserStateData();

  const buildFilter = useCallback(
    () => ({
      orderBy: currentFilter,
      query: currentQuery,
    }),
    [currentFilter, currentQuery]
  );

  const onFetchError = useCallback(() => {
    setErrorMessage('error');
  }, []);

  const onFetchResult = useCallback((data: PaginationDataType) => {
    setIsLoading(false);
    setUsers(data.paginationItems);
    setTotalRecords(data.totalRecords);
  }, []);

  const fetchPagination = useCallback(
    (page?: number) => {
      FetchPagination(
        paginationType,
        page || 0,
        10,
        buildFilter(),
        onFetchResult,
        onFetchError,
        languageRoute
      );
    },
    [paginationType, onFetchResult, onFetchError, buildFilter, languageRoute]
  );

  const onPageChange = useCallback(
    (page: number) => {
      fetchPagination(page);
    },
    [fetchPagination]
  );

  const searchQuery = useCallback(query => {
    setQuery(query);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchPagination();
  }, [fetchPagination]);

  const stopImpersonation = () => {
    StopImpersonation(impersonatePageUrl, languageRoute);
  };

  const startImpersonation = (userId: string) => {
    StartImpersonation(
      userId,
      impersonatePageUrl,
      setErrorMessage,
      languageRoute
    );
  };

  const headers = [
    customerName,
    customerId,
    cityLabel,
    addressLabel,
    zipCodeLabel,
  ];
  return (
    <>
      <Content>
        <TopContent>
          <Heading {...applyEditModeAttr(inEditMode && 'Heading')}>
            {heading}
          </Heading>
          <Intro {...applyEditModeAttr(inEditMode && 'IntroText')}>
            {introText}
          </Intro>
          <XhtmlComponent
            {...applyEditModeAttr(inEditMode && 'BodyText')}
            content={mainBody}
          />
        </TopContent>
        {UserState.isImpersonating && (
          <ImpersonatingInfo
            stopImpersonate={stopImpersonation}
            userState={UserState}
          />
        )}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <styled.Hr />
        <SearchContainer>
          <SubHeading>{findUsersToImpersonate}</SubHeading>
          <SearchInput>
            <SearchComponent
              searchQuery={searchQuery}
              placeHolder={searchForUsers}
              buttonText={search}
              isMobile={isMobile}
              label={searchLabel}
            />
            <Filters>
              <FormLabel>{filterLabel}</FormLabel>
              <DropDown>
                <GenericDropDown
                  values={['ASC', 'DESC']}
                  currentValue={currentFilter}
                  setSelectedValue={setFilter}
                />
              </DropDown>
            </Filters>
          </SearchInput>
        </SearchContainer>
        <TepeList isLoading={isLoading} headers={headers}>
          {updatedUsers &&
            updatedUsers.map(
              (item: ImpersonateUserViewModel, index: number) => (
                <ImpersonateListItem
                  item={item}
                  impersonate={startImpersonation}
                  stopImpersonate={stopImpersonation}
                  key={item.userId}
                  headers={headers}
                  isActive={item.isImpersonating}
                />
              )
            )}
        </TepeList>
        {Boolean(totalRecords) && (
          <Pagination
            totalRecords={totalRecords}
            numberOfRecords={10}
            onPageChange={onPageChange}
          />
        )}
      </Content>
    </>
  );
}
const ErrorMessage = styled.p({
  margin: { top: theme.spacing(2), bottom: theme.spacing(2) },
});
const DropDown = styled.div({
  margin: {
    top: theme.spacing(3),
  },
});

const FormLabel = styled.label({
  font: { size: theme.beta, weight: theme.fontWeight.bold },
  position: 'relative',
  padding: { y: theme.spacing(3) },
  backgroundColor: 'inherit',
});

const Filters = styled.div({
  width: '25%',
  ...media(theme.mediaQuery.mediaMaxLarge, {
    width: 'auto',
    paddingTop: theme.spacing(4),
  }),
});

const SearchInput = styled.div({
  display: 'flex',
  justifyContent: 'space-between',

  ...media(theme.mediaQuery.mediaMaxLarge, {
    flexDirection: 'column',
  }),
});
const SearchContainer = styled.div({
  margin: { top: theme.spacing(11), bottom: theme.spacing(11) },
});

const TopContent = styled.div({
  margin: { x: 'auto' },
  width: '100%',
  maxWidth: theme.blockMaxWidthMobile,

  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { bottom: theme.spacing(13) },
    padding: { x: theme.spacing(2), y: theme.none },
  }),
  ...media(theme.mediaQuery.mediaMinMedium, {
    maxWidth: theme.maxWidthSmall,
  }),
});

const Content = styled.div({
  margin: { y: theme.spacing(6), x: 'auto' },
  width: '100%',
  color: theme.black,
  maxWidth: theme.blockMaxWidthMobile,
  padding: {
    x: theme.spacing(4),
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { bottom: theme.spacing(13) },
    padding: { x: theme.spacing(2), y: theme.none },
  }),
  ...media(theme.mediaQuery.mediaMinMedium, {
    maxWidth: theme.maxWidthSmall,
  }),
});

const Heading = styled.h1({
  maxWidth: theme.maxWidthSmall,
  font: { size: theme.theta, weight: theme.fontWeight.lighter },
  marginBottom: theme.spacing(6),
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: { size: theme.iota },
    marginBottom: theme.spacing(8),
  }),
});

const SubHeading = styled.h2({
  margin: { bottom: theme.spacing(8) },
});

const Intro = styled.p({
  font: { size: theme.gamma, weight: theme.fontWeight.lighter },
  lineHeight: theme.lineHeight.normal,
  letterSpacing: theme.letterSpacing.nearMedium,
  marginBottom: theme.spacing(8),
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.delta,
  }),
});

export default ImpersonatePage;
