export async function FetchPagination(
  paginationType: string,
  currentPage: number,
  numberOfRecords: number,
  filter: {},
  onResult: (x: any) => void, // TODO: https://stackoverflow.com/a/49563070 - this could be a better solution. Let's wait for other implementations
  onError: (message: string) => void,
  languageRoute: string
) {
  const body = JSON.stringify({
    startIndex: currentPage * numberOfRecords,
    numberOfRecords,
    returnTotalCount: true,
    paginationType,
    filter,
  });
  const res = await fetch(`/paginationfilter?culture=${languageRoute}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  });

  if (res.status === 200) {
    const data = await res.json();

    if (data.errorMessage) {
      onError(data.errorMessage);
      return;
    }

    if (data) {
      onResult(data);
      return;
    }
  }

  onError(res.statusText);
}
