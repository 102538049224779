import React from 'react';
import { styled, theme } from '../Theme';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import useMedia from '../Shared/Hooks/useMedia';
import { SmallButton } from '../Shared/Button/Button';
import { StyleOrStyleArray } from '@glitz/type';
import IconLink from '../Shared/IconLink/IconLink';
import { LinkType } from '../Enums/LinkType.enum';
import { media } from '@glitz/core';
import UserState from '../Shared/Models/UserState.interface';
type PropTypes = {
  stopImpersonate: () => void;
  userState?: UserState;
};

function ImpersonatingInfo({ stopImpersonate, userState }: PropTypes) {
  const {
    translations: {
      'impersonatePage/stopImpersonation': stopImpersonation,
      'impersonatePage/activeImpersonation': activeImpersonation,
    },
  } = useAppSettingsData();
  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);

  return (
    <ImpersonatingInfoContainer>
      <Heading>{activeImpersonation}</Heading>
      <InfoBoxBorder>
        <InfoItemsContainer>
          <StyledDiv>
            <StyledTextHeader>{userState?.impersonatedUser}</StyledTextHeader>
          </StyledDiv>
          <StyledDiv>
            <styled.P>{userState?.impersonatedUserCompanyCode}</styled.P>
          </StyledDiv>
          <styled.Div css={isMobile ? LinkPaddingMobile : {}}>
            {!isMobile && (
              <LinkButtonSecondary onClick={stopImpersonate} isActive>
                <ButtonText>{stopImpersonation}</ButtonText>
              </LinkButtonSecondary>
            )}
            {isMobile && (
              <StyledIconLink onClick={stopImpersonate} type={LinkType.Arrow}>
                {stopImpersonation}
              </StyledIconLink>
            )}
          </styled.Div>
        </InfoItemsContainer>
      </InfoBoxBorder>
    </ImpersonatingInfoContainer>
  );
}

const LinkPaddingMobile: StyleOrStyleArray = {
  paddingTop: theme.spacing(6),
};

const StyledTextHeader = styled.p({
  fontWeight: 'bold',
});

const Heading = styled.h4({
  margin: { bottom: theme.spacing(2) },
});
const StyledDiv = styled.div({
  margin: { top: 'auto', bottom: 'auto' },
  ...media(theme.mediaQuery.mediaMaxLarge, {
    paddingBottom: theme.spacing(2),
  }),
});

const ImpersonatingInfoContainer = styled.div({
  margin: { bottom: theme.spacing(13) },
});

const InfoItemsContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  margin: { xy: theme.spacing(4) },

  ...media(theme.mediaQuery.mediaMaxLarge, {
    flexDirection: 'column',
  }),
});

const InfoBoxBorder = styled.div({
  border: {
    xy: {
      style: 'solid',
      width: theme.tiny,
      color: theme.black,
    },
  },
});

const LinkButtonSecondary = styled(SmallButton, {
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  letterSpacing: theme.letterSpacing.medium,
  backgroundColor: theme.blueDark,
  padding: { x: theme.spacing(5.5), y: theme.spacing(3) },
  color: theme.white,
  borderRadius: 'none',
});

const ButtonText = styled.span({
  margin: { left: '0', top: 'auto', bottom: 'auto' },
  font: { size: theme.beta },
  padding: { bottom: theme.tiny },
});

const StyledIconLink = styled(IconLink, {
  marginLeft: 'auto',
});

export default ImpersonatingInfo;
