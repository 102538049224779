import React from 'react';
import { media } from '@glitz/core';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { styled, theme } from '../Theme';
import { SmallButton } from '../Shared/Button/Button';
import IconLink from '../Shared/IconLink/IconLink';
import { LinkType } from '../Enums/LinkType.enum';
import { StyleOrStyleArray } from '@glitz/type';
import useMedia from '../Shared/Hooks/useMedia';
import ImpersonateUserViewModel from '../ImpersonatePage/Models/ImpersonateUserViewModel.interface';

type PropTypes = {
  item: ImpersonateUserViewModel;
  impersonate: (userId: string) => void;
  stopImpersonate: () => void;
  headers: string[];
  isActive: boolean;
};

function ImpersonateListItem({
  item,
  impersonate,
  stopImpersonate,
  headers,
  isActive = false,
}: PropTypes) {
  const {
    translations: {
      'impersonatePage/impersonating': impersonatingLabel,
      'impersonatePage/impersonate': startImpersonateLabel,
    },
  } = useAppSettingsData();
  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);

  return (
    <Row>
      <StyledTableDataHeader>{item.organizationName}</StyledTableDataHeader>
      <TableData>
        {isMobile && headers[1]} {item.code}
      </TableData>
      <TableData>
        {isMobile && headers[2]} {item.city}
      </TableData>
      <TableData>
        {isMobile && headers[3]} {item.address}
      </TableData>
      <TableData>
        {isMobile && headers[4]} {item.zipCode}
      </TableData>
      <TableData>
        <Spacing>
          {!isMobile && (
            <>
              <LinkButton
                isActive={isActive}
                onClick={
                  isActive
                    ? () => stopImpersonate()
                    : () => impersonate(item.userId)
                }
                css={isActive ? {} : LinkButtonSecondary}
              >
                <ButtonText>
                  {isActive ? impersonatingLabel : startImpersonateLabel}
                </ButtonText>
              </LinkButton>
            </>
          )}
          {isMobile && (
            <>
              <StyledIconLink
                onClick={
                  isActive
                    ? () => stopImpersonate()
                    : () => impersonate(item.userId)
                }
                whiteText={false}
                type={LinkType.Arrow}
              >
                {isActive ? impersonatingLabel : startImpersonateLabel}
              </StyledIconLink>
            </>
          )}
        </Spacing>
      </TableData>
    </Row>
  );
}

const Spacing = styled.div({
  margin: { top: theme.spacing(2), bottom: theme.spacing(2) },
});

const StyledIconLink = styled(IconLink, {
  paddingTop: theme.spacing(2),
  marginLeft: 'auto',
});

const TableData = styled.td({
  ...media(theme.mediaQuery.mediaMaxLarge, {
    paddingBottom: theme.spacing(2),
  }),
});

const Row = styled.tr({
  borderBottomWidth: theme.tiny,
  borderBottomColor: theme.lightGray,
  ...media(theme.mediaQuery.mediaMaxLarge, {
    display: 'grid',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }),
});

const StyledTableDataHeader = styled.td({
  fontWeight: theme.fontWeight.bold,
  ...media(theme.mediaQuery.mediaMaxLarge, {
    paddingBottom: theme.spacing(2),
  }),
});

const LinkButton = styled(SmallButton, {
  width: '-webkit-fill-available',
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  letterSpacing: theme.letterSpacing.medium,
  padding: { x: theme.none, y: theme.spacing(3) },
  border: { xy: { width: theme.none } },
  borderRadius: theme.none,
  backgroundColor: theme.blueDark,
  color: theme.white,
});

const LinkButtonSecondary: StyleOrStyleArray = {
  backgroundColor: theme.white,
  color: theme.navigationItem,
  border: { xy: { width: theme.spacing(0.5), color: theme.white } },
  ':hover': {
    color: theme.blueDark,
    border: { xy: { color: theme.blueDark } },
  },
};

const ButtonText = styled.span({
  font: { size: theme.beta },
});

export default ImpersonateListItem;
