import React from 'react';
import { styled, theme } from '../Theme';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import useMedia from '../Shared/Hooks/useMedia';

type PropTypes = {
  children: React.ReactNode;
  isLoading: boolean;
  headers: string[];
};

function TepeList({ children, isLoading, headers }: PropTypes) {
  const {
    translations: { 'common/loading': loading },
  } = useAppSettingsData();
  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);

  return isLoading ? (
    <styled.P>{loading}</styled.P>
  ) : (
    <TableWraper>
      <Table>
        <styled.Tbody>
          {!isMobile && (
            <Row>
              {headers.map((value, index) => {
                return (
                  <Header key={value + index}>
                    <Spacing>{value}</Spacing>
                  </Header>
                );
              })}
            </Row>
          )}
          {children}
        </styled.Tbody>
      </Table>
    </TableWraper>
  );
}

const Spacing = styled.div({
  margin: { bottom: theme.spacing(4), right: theme.spacing(4) },
});

const Row = styled.tr({
  borderBottomWidth: theme.tiny,
  borderBottomColor: theme.lightGray,
});
const Header = styled.th({
  textAlign: 'left',
});

const Table = styled.table({
  padding: { xy: theme.spacing(2) },
  width: '-webkit-fill-available',
});
const TableWraper = styled.div({
  marginBottom: theme.spacing(13),
});

export default TepeList;
