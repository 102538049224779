import { updateHeader } from '../Shared/Models/Headers/useQueryHeader';
import { updateUserState } from '../Shared/UserContextProvider/useQueryUserState';

export async function StartImpersonation(
  userId: string,
  impersonatePageUrl: string,
  setError: (value: string) => void,
  languageRoute: string
) {
  const res = await fetch(
    `${impersonatePageUrl}/StartImpersonate?userId=${userId}`,
    {
      method: 'POST',
    }
  );
  if (res.ok) {
    const { success, errorMessage } = await res.json();
    if (!success) {
      setError(errorMessage);
    } else {
      updateUserState();
      updateHeader(languageRoute);
    }
  }
}

export async function StopImpersonation(
  impersonatePageUrl: string,
  languageRoute: string
) {
  const res = await fetch(`${impersonatePageUrl}/StopImpersonating`, {
    method: 'POST',
  });
  if (res.ok) {
    updateUserState();
    updateHeader(languageRoute);
  }
}
