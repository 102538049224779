import React, { useState } from 'react';
import { media } from '@glitz/core';
import { styled, theme } from '../../Theme';
import { SmallButton } from '../../Shared/Button/Button';

type PropTypes = {
  searchQuery: (input: string) => void;
  placeHolder: string;
  buttonText: string;
  isMobile: boolean;
  label?: string;
};

function SearchComponent({
  searchQuery,
  placeHolder,
  buttonText,
  label,
}: PropTypes) {
  const [query, setQuery] = useState<string>('');

  return (
    <styled.Div>
      {label && <FormLabel>{label}</FormLabel>}
      <Form>
        <FormInput
          placeholder={placeHolder}
          type={'text'}
          onChange={e => {
            setQuery(e.currentTarget.value);
          }}
          onKeyDown={e => e.key === 'Enter' && searchQuery(query)}
        />
        <SearchButton onClick={() => searchQuery(query)} isActive>
          <styled.P>{buttonText}</styled.P>
        </SearchButton>
      </Form>
    </styled.Div>
  );
}

const Form = styled.div({
  display: 'flex',
  margin: { top: theme.spacing(3) },
  width: '100%',
});

const FormInput = styled.input({
  maxHeight: theme.spacing(11),
  display: 'flex',
  border: { xy: { width: theme.tiny, color: theme.black } },
  width: '100%',
  padding: { x: theme.spacing(3), y: theme.spacing(3), left: theme.spacing(4) },
  ...media(theme.mediaQuery.mediaMaxLarge, {
    width: '-webkit-fill-available',
  }),
});

const FormLabel = styled.label({
  font: { size: theme.beta, weight: theme.fontWeight.bold },
  position: 'relative',
  padding: { y: theme.spacing(3) },
  backgroundColor: 'inherit',
});

const SearchButton = styled(SmallButton, {
  maxHeight: theme.spacing(11),
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  letterSpacing: theme.letterSpacing.medium,
  backgroundColor: theme.blueDark,
  padding: {
    y: theme.spacing(3),
    x: theme.spacing(4),
  },
  color: theme.white,
  border: { xy: { width: 0 } },
  borderRadius: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: theme.spacing(2),

  ...media(theme.mediaQuery.mediaMaxLarge, {
    width: 'auto',
  }),

  ...media(theme.mediaQuery.mediaMinLarge, {
    width: 'auto',
  }),
});

export default SearchComponent;
