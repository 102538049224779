import { media } from '@glitz/core';
import { StyledProps } from '@glitz/react';
import React, { useState, useEffect } from 'react';
import { styled, theme } from '../../Theme';
import useMedia from '../Hooks/useMedia';
import { SmallButton, IconButton } from './../Button/Button';
import GenericDropDown from './../ValueDropDown/GenericDropDown';
import ChevronIcon from './../Icons/ChevronIcon';
import { useAppSettingsData } from '../AppSettingsProvider/AppSettingsProvider';

type PropTypes = {
  numberOfRecords: number;
  totalRecords: number;
  onPageChange: (x: any) => void;
  currentPage?: number;
};

function Pagination({
  totalRecords,
  numberOfRecords,
  onPageChange,
  currentPage: currentPageProp,
}: PropTypes) {
  const {
    translations: { 'common/prev': prevText, 'common/next': nextText },
  } = useAppSettingsData();
  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const totalAmountSteps = [
    ...Array(Math.ceil(totalRecords / numberOfRecords)),
  ];

  useEffect(() => {
    if (currentPageProp !== undefined) {
      setCurrentPage(currentPageProp);
    }
  }, [currentPageProp]);

  const updatePagination = (currentPage: number) => {
    onPageChange(currentPage);
    setCurrentPage(currentPage);
  };

  const nextPage = () => {
    updatePagination(currentPage + 1);
  };

  const prevPage = () => {
    updatePagination(currentPage - 1);
  };

  const goToPage = (index: number | string) => {
    if (typeof index === 'string') {
      index = parseInt(index);
    }
    updatePagination(index);
  };

  const renderPaginationSteps = () => {
    return (
      <>
        {totalAmountSteps &&
          totalAmountSteps.map((_, index) => {
            return (
              <StyledStep
                isActive={index === currentPage}
                onClick={() => goToPage(index)}
                key={index}
              >
                {index + 1}
              </StyledStep>
            );
          })}
      </>
    );
  };

  return (
    <PaginationWrapper>
      {totalRecords ? (
        <>
          {isMobile ? (
            <StyledIconButton
              icon={<ArrowLeft />}
              disabled={!currentPage}
              onClick={prevPage}
              isActive
            />
          ) : (
            <PrevButton disabled={!currentPage} onClick={prevPage} isActive>
              {prevText}
            </PrevButton>
          )}
          {isMobile ? (
            <GenericDropDown
              values={totalAmountSteps}
              currentValue={currentPage}
              setSelectedValue={(result: string) => goToPage(result)}
              isPagination
            />
          ) : (
            <StepsWrapper>{renderPaginationSteps()}</StepsWrapper>
          )}
          {isMobile ? (
            <StyledIconButton
              icon={<ArrowRight />}
              disabled={totalAmountSteps.length - 1 === currentPage}
              onClick={nextPage}
              isActive
            />
          ) : (
            <NextButton
              disabled={totalAmountSteps.length - 1 === currentPage}
              onClick={nextPage}
              isActive
            >
              {nextText}
            </NextButton>
          )}
        </>
      ) : null}
    </PaginationWrapper>
  );
}

const PrevButtonStyle = {
  minWidth: theme.paginationButtonWidth,
  ...media(theme.mediaQuery.mediaMaxLarge, {
    marginRight: theme.spacing(4),
  }),
};

const NextButtonStyle = {
  minWidth: theme.paginationButtonWidth,
  ...media(theme.mediaQuery.mediaMaxLarge, {
    marginLeft: theme.spacing(4),
  }),
};

const PaginationWrapper = styled.div({
  display: 'flex',
  alignItems: 'stretch',
  margin: { x: 'auto', y: 0 },
  width: '100%',
  justifyContent: 'space-between',
});

const StepsWrapper = styled.div({
  maxWidth: theme.paginationStepsMaxWidth,
  overflow: 'auto',
  display: 'flex',
  alignItems: 'center',
});

const PrevButton = styled(SmallButton, {
  ...PrevButtonStyle,
});

const StyledIconButton = styled(IconButton, { height: theme.spacing(14) });

const NextButton = styled(SmallButton, {
  ...NextButtonStyle,
});

const Step = styled.span({
  margin: { x: theme.spacing(6) },
  color: theme.darkGray,
  ':hover': {
    cursor: 'pointer',
    color: theme.black,
  },
});

const StyledStep = React.memo(
  styled(
    ({
      compose,
      isActive = false,
      children,
      ...restProps
    }: { isActive?: boolean; children: React.ReactNode } & React.HTMLAttributes<
      HTMLDivElement
    > &
      StyledProps) => (
      <Step
        css={compose({
          ...(isActive && {
            fontWeight: theme.fontWeight.bold,
            color: theme.black,
          }),
        })}
        {...restProps}
      >
        {children}
      </Step>
    )
  )
);

const StyledArrow = styled(ChevronIcon, {
  width: theme.spacing(4),
  height: theme.spacing(4),
  fill: theme.white,
});

const ArrowLeft = styled(StyledArrow, {
  transform: 'rotate(90deg)',
});

const ArrowRight = styled(StyledArrow, {
  transform: 'rotate(-90deg)',
});

export default React.memo(Pagination);
